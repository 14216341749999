import {
  IFundRequest,
  IMAPFundRequest,
} from "shared/programs/migration-acceleration-program/2024/fund-request/types/SchemaTypes";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { removeCurrencyFormatting } from "shared/programs/migration-acceleration-program/2024/fund-request/util/currency";

export const extractFundRequestBaseDetails = (fundRequest: IFundRequest) => {
  const owner = fundRequest?.owner?.userName;
  const stage = fundRequest["stage"];
  const fundingType = fundRequest["fundingType"];
  const program = fundRequest["program"];
  const fundRequestId = fundRequest["fundRequestId"];
  const migrationPhase = fundRequest["migrationPhase"];
  const subProgram = "Migration acceleration program";
  const status = fundRequest["status"];
  const greenfieldIncentiveEligible =
    fundRequest?.incentives?.greenfield?.eligible || false;
  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;
  const vmwareMSPAcknowledgement =
    fundRequest?.incentives?.vmware?.bonusOption === "vmware_manage";
  const numberOfVmwareToMigrate =
    fundRequest?.incentives?.vmware?.numberOfVmwareToMigrate;
  const totalNumberOfWorkloadsInUse =
    fundRequest?.incentives?.vmware?.totalNumberOfWorkloadsInUse;

  return {
    stage,
    fundingType,
    program,
    migrationPhase,
    subProgram,
    fundRequestId,
    owner,
    status,
    greenfieldIncentiveEligible,
    vmwareAcknowledgement,
    vmwareMSPAcknowledgement,
    numberOfVmwareToMigrate,
    totalNumberOfWorkloadsInUse,
  };
};

export const extractClaimBaseDetails = (claim: IGenericObject) => {
  if (!claim) return {};
  const fundClaimId = claim?.fundClaimId;
  const status = claim?.status;
  const stage = claim?.stage;
  const claimLocalAmountCap = formatCurrency(claim?.claimLocalAmountCap);
  const localCurrency = claim?.localCurrency;
  return {
    fundClaimId,
    status,
    stage,
    claimLocalAmountCap,
    localCurrency,
  };
};

export const extractClaimActualDetails = (claim: IGenericObject) => {
  if (!claim) return {};

  const startDate = claim?.startDate;
  const endDate = claim?.endDate;
  const dueDate = claim?.dueDate;
  const productionDate = claim?.inProductionDate;
  const awsAccountId = claim?.awsAccountId;
  const claimLocalAmount = formatCurrency(claim?.claimLocalAmount);
  return {
    startDate,
    endDate,
    dueDate,
    productionDate,
    awsAccountId,
    claimLocalAmount,
  };
};

export const extractClaimInvoiceDetails = (claim: IGenericObject) => {
  if (!claim) return {};
  const invoice: IGenericObject = claim?.invoice;

  let invoiceDetails = {};
  if (invoice) {
    const invoiceLocalAmount = formatCurrency(invoice?.localAmount);
    const scheduledPaymentDate = invoice?.scheduledPaymentDate;
    const description = invoice?.description;

    invoiceDetails = {
      ...invoiceDetails,
      invoiceLocalAmount,
      scheduledPaymentDate,
      description,
    };
  }
  return invoiceDetails;
};

export const formatCurrency = (value: string, removeDecimals = true) => {
  if (!value) return "";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [amount, _currency] = value.split(" ");
  const parsedValue = removeDecimals ? parseInt(amount) : parseFloat(amount);
  if (isNaN(parsedValue)) return "";
  return parsedValue.toLocaleString("en-US");
};

export const migrationEligibilityAdapter = (apiResponse: IGenericObject) => {
  const fundRequest = apiResponse["fundRequest"];
  const { arrUsdAmount, modernizationArrUsdAmount, ...migrationEligibility } =
    extractMigrationEligibility(fundRequest);

  return {
    ...extractFundRequestBaseDetails(fundRequest),
    annualRunRate: removeCurrencyFormatting(arrUsdAmount),
    modernizationArrUsdAmount: removeCurrencyFormatting(
      modernizationArrUsdAmount
    ),
    ...migrationEligibility,
  };
};

export const extractInvoiceAddressDetails = (fundRequest: IMAPFundRequest) => {
  let invoiceAddressDetails = {};
  const fundingDetails = fundRequest["fundingDetails"];

  const invoiceAddress = fundingDetails["invoiceAddress"];
  const invoiceEntityName = invoiceAddress["name"];
  const invoiceRemitStreet = invoiceAddress["street"];
  const invoiceRemitUnit = invoiceAddress["unit"];
  const invoiceRemitCity = invoiceAddress["city"];
  const invoiceRemitState = invoiceAddress["state"];
  const invoiceRemitPostalCode = invoiceAddress["postalCode"];
  const invoiceRemitCountry = invoiceAddress["country"];

  invoiceAddressDetails = {
    invoiceEntityName,
    invoiceRemitStreet,
    invoiceRemitUnit,
    invoiceRemitCity,
    invoiceRemitState,
    invoiceRemitPostalCode,
    invoiceRemitCountry,
  };
  return invoiceAddressDetails;
};

export const extractMigrationEligibility = (fundRequest: IFundRequest) => {
  const modernizationArrUsdAmount = formatCurrency(
    fundRequest["modernizationArrUsdAmount"]
  );
  const arrUsdAmount = formatCurrency(fundRequest?.arrUsdAmount);
  const eligibleArrUsdAmount = formatCurrency(
    fundRequest?.eligibleArrUsdAmount
  );
  const modernizationIncentiveEligible =
    fundRequest?.modernizationIncentiveEligible;

  const accurateWorkloadAcknowledgement =
    fundRequest?.accurateWorkloadAcknowledgement;

  const vmwareAcknowledgement =
    fundRequest?.incentives?.vmware?.eligible || false;
  const vmwareMSPAcknowledgement =
    fundRequest?.incentives?.vmware?.bonusOption === "vmware_manage";

  return {
    modernizationArrUsdAmount,
    modernizationIncentiveEligible,
    eligibleArrUsdAmount,
    arrUsdAmount,
    accurateWorkloadAcknowledgement,
    vmwareAcknowledgement,
    vmwareMSPAcknowledgement,
  };
};

export const extractPurchaseOrderDetails = (fundRequest: IFundRequest) => {
  const fundingDetails = fundRequest["fundingDetails"];
  const purchaseOrder = fundingDetails["purchaseOrder"];

  let poDetails = {};

  if (purchaseOrder) {
    const poNumber = purchaseOrder["number"];
    const poRequestNumber = purchaseOrder["requestNumber"];
    const poIssued = purchaseOrder["issued"];
    const poIssuedDate = purchaseOrder["issuedDate"];
    const poLocalAmount = formatCurrency(purchaseOrder["localAmount"]);
    const poUsdAmount = formatCurrency(purchaseOrder["usdAmount"]);
    poDetails = {
      ...poDetails,
      poNumber,
      poRequestNumber,
      poIssued,
      poIssuedDate,
      poLocalAmount,
      poUsdAmount,
    };
  }
  return poDetails;
};

export const extractProjectDetailsFields = (fundRequest: IFundRequest) => {
  const project = fundRequest["project"];

  let projectDetails = {};
  if (project) {
    const activityName = project["activityName"];
    const description = project["description"];
    const plannedStartDate = project["startDate"];
    const plannedEndDate = project["endDate"];
    const awsAccountId = project["awsAccountId"];
    const unChangedAwsAccountId = project["awsAccountId"];
    const location = project["location"];
    const executedCountry = location["country"];
    const states = location["states"];
    const executedState = Array.isArray(states) ? states.join(",") : states;
    const customerConsidering = project["customerConsidering"];
    const extensions = project["extensions"];
    let extendedStartDate;
    let extendedEndDate;
    if (extensions && extensions.length > 0) {
      const latestExtension = extensions.at(-1);
      extendedStartDate = latestExtension?.startDate;
      extendedEndDate = latestExtension?.endDate;
    }

    projectDetails = {
      activityName,
      description,
      plannedStartDate,
      plannedEndDate,
      extendedStartDate,
      extendedEndDate,
      awsAccountId,
      unChangedAwsAccountId,
      executedCountry,
      executedState,
      customerConsidering,
    };
  }

  return projectDetails;
};

export const claimAdapter = (
  claim: IGenericObject,
  fund_request: IGenericObject | null = null
) => {
  if (!claim) return {};
  if (!fund_request) return {};
  const claimId = claim["fundClaimId"];
  const claimPlan =
    claimId && fund_request?.fundingDetails?.claimPlans[claimId];
  const numberOfVmwareToMigrateFromPlan =
    claimPlan?.properties?.vmwareBonus?.numberOfVmwareToMigrate;
  const totalNumberOfWorkloadsInUseFromPlan =
    claimPlan?.properties?.vmwareBonus?.totalNumberOfWorkloadsInUse;
  const numberOfVmwareToMigrate =
    claim?.properties?.vmwareBonus?.numberOfVmwareToMigrate;
  const totalNumberOfWorkloadsInUse =
    claim?.properties?.vmwareBonus?.totalNumberOfWorkloadsInUse;
  const toolsUsedForGeneratingMigrationReport =
    claim?.properties?.vmwareBonus?.toolsUsedForGeneratingMigrationReport &&
    claim?.properties?.vmwareBonus?.toolsUsedForGeneratingMigrationReport.join(
      ","
    );
  const vmwareClaim = claimPlan?.properties !== undefined;
  const vmwareMSPAcknowledgement =
    claimPlan?.properties?.vmwareBonus?.plannedBonusOption === "vmware_manage";
  const isVMwareManagedServicesInScope =
    claim?.properties?.vmwareBonus?.actualBonusOption === "vmware_manage";
  return {
    claimId: claim?.fundClaimId,
    claimDueDate: claim?.dueDate,
    stage: claim?.stage,
    claimAmount: formatCurrency(claim?.claimLocalAmountCap),
    awsAccountId: claim?.awsAccountId,
    startDate: claim?.startDate,
    endDate: claim?.endDate,
    productionDate: claim?.inProductionDate,
    hasSowChanged: claim?.hasSowChanged,
    artifacts: claim?.artifacts,
    currency: claim?.localCurrency,
    invoice: extractClaimInvoiceDetails(claim),
    completionMilestone: claim?.completionMilestone,
    proofOfPerformance: claim?.proofOfPerformance,
    actualClaimAmount: formatCurrency(
      claim?.claimLocalAmount || claim?.claimLocalAmountCap
    ),
    vmwareClaim: vmwareClaim,
    vmwareMSPAcknowledgement: vmwareMSPAcknowledgement,
    isVMwareManagedServicesInScope: isVMwareManagedServicesInScope,
    numberOfVmwareToMigrate:
      numberOfVmwareToMigrate || numberOfVmwareToMigrateFromPlan,
    totalNumberOfWorkloadsInUse:
      totalNumberOfWorkloadsInUse || totalNumberOfWorkloadsInUseFromPlan,
    toolsUsedForGeneratingMigrationReport:
      toolsUsedForGeneratingMigrationReport,
  };
};

export const extractClaimIdToClaim = (apiResponse: IGenericObject) => {
  const claims = apiResponse?.claims;
  const claimIdsToClaim: IGenericObject = {};
  Object.keys(claims).forEach((claimId) => {
    const claim = claims[claimId];

    claimIdsToClaim[claimId] = claimAdapter(claim);
  });

  return claimIdsToClaim;
};

export const extractFundingDetails = (fundRequest: IFundRequest) => {
  const funding = fundRequest["fundingDetails"];
  let fundingDetails = {};
  if (funding) {
    const totalCostOfActivity = formatCurrency(
      funding["totalCostOfActivityLocalAmount"]
    );
    const cashFundingAmount = formatCurrency(
      funding["totalFundingRequestedFromAwsLocalAmount"]
    );
    const approvedCashLocalAmount = formatCurrency(
      funding["approvedCashLocalAmount"],
      true
    );

    const currency = funding["localCurrency"];
    const currencyConversionRateToUsd =
      funding?.currencyConversionRateToUsd?.rate?.toFixed(8);
    const invoiceAddressDetails = extractInvoiceAddressDetails(fundRequest);
    const claimPriority = funding.priorityToClaim;

    fundingDetails = {
      ...invoiceAddressDetails,
      totalCostOfActivity,
      cashFundingAmount,
      approvedCashLocalAmount,
      currency,
      currencyConversionRateToUsd,
      claimPriority,
    };
  }
  return fundingDetails;
};
