import React, { useEffect, useState } from "react";

import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";
import InfoLink from "../../../../../../../../../shared/components/InfoLink";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import { getFundRequestComments } from "external/util/services/data/FundRequestService";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "external/components/common/TableEmptyState";
import TableNoMatchState from "external/components/common/TableNoMatchState";
import { formatReasonCodes } from "external/components/FundRequest/Review/Sections/FundRequestComments/util";
import { yyyymmddLocalDateFormat } from "shared/util/common/date";
import {
  CollectionPreferences,
  Pagination,
} from "@amzn/awsui-components-react";
import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences/interfaces";
import { STAGE_NAME } from "shared/components/StatusBar/util";
import {
  filter,
  getColumnsAsVisible,
  getCount,
  getTableCollectionPreferences,
} from "external/programs/migration-acceleration-program/2024/fund-request/components/shared/table/util";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { handleApiError, Result, Success } from "shared/util/api/util";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import { timestampComparator } from "../../../../../../../../../shared/util/common/helper";
import { TableProps } from "@amzn/awsui-components-react/polaris/table/interfaces";

interface IFundRequestHistory {
  sequenceNumber: number;
  id: string;
  date: string;
  stage: string;
  action: string;
  awsComments: string;
  partnerComments: string;
  rejectionReason: string;
}

interface IFundRequestHistoryAPIModel {
  sequenceNumber: number;
  fundRequestId: string;
  cashClaimId: string;
  date: { timestamp: number };
  stage: string;
  action: string;
  commentsToPartner: string | null;
  partnerComments: string | null;
  reasonCodes: string[];
}

const TABLE_RESOURCE_NAME = "stage actions";

const FundRequestHistory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [history, setHistory] = useState<IGenericObject[]>([]);
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 10,
      visibleContent: getColumnsAsVisible({ columnDefinitions }),
    });
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);

  useEffect(() => {
    const getHistory = async () => {
      let data: IFundRequestHistory[] = [];
      let nextPageToken = "";
      while (nextPageToken != null) {
        try {
          // @ts-expect-error Still in javascript, so the implementation details don't matter
          const response = await retryApiCall({
            callApi: getFundRequestComments({ nextPageToken }),
          });
          if (hasError(response)) {
            return handleApiError(response.errorType)(response);
          }

          const historyEvents = response.history.map(
            ({
              sequenceNumber,
              fundRequestId,
              cashClaimId,
              date,
              stage,
              action,
              commentsToPartner,
              partnerComments,
              reasonCodes,
            }: IFundRequestHistoryAPIModel) => {
              const id = cashClaimId || fundRequestId;
              const eventDate = yyyymmddLocalDateFormat(
                new Date(date.timestamp)
              );

              const rejectionReason = formatReasonCodes(reasonCodes);

              const getUserFriendlyStageName = ({
                stage,
              }: {
                stage: string;
              }) => {
                if (!Object.keys(STAGE_NAME).includes(stage)) return "Partner";

                // @ts-expect-error Legacy javascript code
                return STAGE_NAME[stage];
              };

              const formattedStage = getUserFriendlyStageName({ stage });

              return {
                id,
                sequenceNumber,
                date: eventDate,
                stage: formattedStage,
                action,
                commentsToPartner,
                partnerComments,
                rejectionReason,
              };
            }
          );

          data = data.concat(historyEvents);
          nextPageToken = response["nextPageToken"];
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      }
      setHistory(data);
      return new Result<Success>({});
    };

    getHistory();
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(history || [], {
      filtering: {
        empty: <TableEmptyState resourceName={TABLE_RESOURCE_NAME} />,
        noMatch: (
          <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
        ),
        filteringFunction: (item, filteringText) => {
          return filter({ item, filteringText, columnDefinitions });
        },
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "date",
            sortingComparator: (e1, e2) =>
              timestampComparator(e1.date, e2.date),
          },
          isDescending: true,
        },
      },
      selection: {},
      pagination: { pageSize: preferences.pageSize },
    });

  console.debug({ filterProps });

  const count = getCount({ collection: history });

  return (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      items={items}
      loading={isLoading}
      wrapLines={true}
      loadingText={`Loading ${TABLE_RESOURCE_NAME}`}
      visibleColumns={preferences.visibleContent}
      header={
        <Header
          counter={count}
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("details.history");
              }}
            />
          }
        >
          Fund request history
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <CollectionPreferences
          {...getTableCollectionPreferences({
            name: TABLE_RESOURCE_NAME,
            columnDefinitions,
            editable: ({ id }: { id?: string }) =>
              id !== "sequenceNumber" && id !== "id",
          })}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder={`Find ${TABLE_RESOURCE_NAME}`}
        />
      }
    />
  );
};

const columnDefinitions: Array<TableProps.ColumnDefinition<IGenericObject>> = [
  {
    id: "id",
    header: "Request ID",
    cell: (item: IFundRequestHistory) => item.id,
    sortingField: "id",
    isRowHeader: true,
  },
  {
    id: "date",
    header: "Date",
    cell: (item: IFundRequestHistory) => item.date,
    sortingField: "date",
    sortingComparator: (e1, e2) => timestampComparator(e1.date, e2.date),
    isRowHeader: true,
  },
  {
    id: "stage",
    header: "Stage",
    cell: (item: IFundRequestHistory) => item.stage,
    sortingField: "stage",
    isRowHeader: true,
  },
  {
    id: "action",
    header: "Action",
    cell: (item: IFundRequestHistory) => item.action,
    sortingField: "action",
    isRowHeader: true,
  },
  {
    id: "awsComment",
    header: "AWS comment",
    cell: (item: IFundRequestHistory) => item.awsComments,
    sortingField: "awsComment",
    isRowHeader: true,
  },
  {
    id: "partnerComments",
    header: "Partner comments",
    cell: (item: IFundRequestHistory) => item.partnerComments,
    isRowHeader: true,
  },
  {
    id: "rejectionReason",
    header: "Rejection reason",
    cell: (item: IFundRequestHistory) => item.rejectionReason,
    isRowHeader: true,
  },
];

export default FundRequestHistory;
